<template>
  <v-container>
    <v-card elevation="12" min-height="600">
      <v-card-title>Le mie stanze</v-card-title>
      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-if="rooms.length > 20"
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items="rooms"
          item-key="COGNOME"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Stanze per pagina',
            itemsPerPageAllText: 'Tutte le stanze',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Stanze per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
        </v-data-table>
        <v-simple-table v-else>
          <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            ID
          </th>
          <th class="text-left">
            Stanza
          </th>
          <th class="text-left">
            Piano
          </th>
          <th class="text-left">
            Nome
          </th>
          <th class="text-left">
            Nome (cont)
          </th>
          <th class="text-left">
            Tipo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in rooms"
          :key="item.ID"
        >
          <td>{{ item.ID }}</td>
          <td>{{ item.CODICE_STANZA }}</td>
          <td>{{ item.NUM_PIANO }}</td>
          <td>{{ item.RM_NAME }}</td>
          <td>{{ item.LOCAL_NAME }}</td>
          <td>{{ item.NOME_STANZA }}</td>
        </tr>
      </tbody>
    </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

export default {
  name: "MyRooms",
  data: () => {
    return {
      rooms: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "ID",
        },
        {
          text: "Codice",
          align: "start",
          sortable: true,
          value: "CODICE_STANZA",
        },
        {
          text: "Edificio",
          align: "start",
          sortable: true,
          value: "NOME_EDIFICIO",
        },
        {
          text: "Piano",
          align: "start",
          sortable: true,
          value: "NUM_PIANO",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "RM_NAME",
        },
        {
          text: "Nome (cont)",
          align: "start",
          sortable: false,
          value: "LOCAL_NAME",
        },

        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "NOME_STANZA",
        },
      ];
    },
  },

  created: function () {
    this.loading = true;
    this.$http
      .get(process.env.VUE_APP_PATRIMONIO_API + "/users/" + this.$store.state.userMatricola + '/rooms', {
        headers: {
          authorization: "Bearer " + this.$store.state.authJwt,
        },
      })
      .then((response) => {
        this.rooms = response.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {},
};
</script>